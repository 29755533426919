<template>
  <header>
    <!--<div class="header-top">
<a href="tel:19991533734" target="_blank" rel="noopener noreferrer">
<span>
<strong>(19) 9 9153-3734</strong>
</span>
</a>
<a href="https://api.whatsapp.com/send?phone=5519991533734&amptext=Olá%20Rm%20Mudanças%20e%20Fretes,%20gostaria%20de%20solicitar%20um%20orçamento."
target="_blank" rel="noopener noreferrer">
<span>
<strong>(19) 9 9153-3734</strong>
</span>
</a>
</div> -->
    <nav class="navbar">
      <div>
        <div>
          <!-- <img src="@/assets/logo.png" width="120" alt="logo" /> -->
          <a id="toggle-menu" class="toggle-menu" v-on:click="menu">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
</svg>
          </a>
        </div>
      </div>
      <div class="subnav" :class="{ 'menu-active': isMenuOpen }">
        <ul id="subnav" class="">
          <li class="">
            <router-link class="nav__link" v-on:click="menu" to="/">
              Home
            </router-link>
          </li>
          <li class="">
            <router-link v-on:click="menu" to="/a-empresa/" class="m">A Empresa</router-link>
          </li>
          <li class="">
            <router-link to="/servicos/" v-on:click="menu" class="m">Serviços</router-link>
          </li>
          <li class="">
            <router-link to="/galeria-de-fotos/" v-on:click="menu" aria-current="page" class="m m-active">
              Galeria
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
// import eventBus from '../eventBus.js'
export default {
  name: 'header-cps',
  data () {
    return {
      isMenuOpen: false
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  unmounted () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      localStorage.setItem('width', window.innerWidth)
    },
    menu () {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>
<style lang="scss" scoped>
.header-top {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #333;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.subnav {
  background-color: #fff000;
  position: fixed;
  top: 0;
  left: 0;
  transition: left 0.3s ease;
  z-index: 999999;
  min-width: 100vw !important;

  @media only screen and (max-width: 600px) {
    background-color: #000000;
    background: #000000;
    opacity: .91;
    left: -100%;

    &.menu-active {
      left: 0;
      z-index: 2;
    }
  }

  ul {
    * {
      color: #333 !important;
    }

    display: flex;

    @media only screen and (max-width: 600px) {
      * {
        color: #fff !important;
      }

      flex-direction: column;
    }

    flex-direction: row;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      align-items: center;

      a:hover {
        border-bottom: 2px solid #000 !important;
        color: #000 !important;
      }
    }
  }
}

.toggle-menu {
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 83vw;
    margin: 0;
    padding: 0;
  }
  border: none;
  cursor: pointer;
}

nav {
  display: grid;
  grid-template-rows: repeat(2, auto);

  @media only screen and (max-width: 600px) {
    background-color: #000000;
    background: #000000;
    opacity: .91;
  }

  div {
    height: auto !important;

    &:nth-child(1) {
      height: 64px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
    }
  }

  a {
    padding: 20px;
  }
}

.navbar {
  *{
    min-width: 56px;
    min-height: 56px;
  }
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  min-width: 100vw !important;
  min-height: 48px!important;
  background-color: #fff000;

  @media only screen and (max-width: 600px) {
    justify-content: stretch;
    flex-direction: column;
    padding: 0;
  }
}

.nav-menu {
  @media only screen and (max-width: 600px) {
    border-radius: 0;
    background: #333;
    color: #fff !important;
  }

  display: grid;
  transition: all 0.25s ease-in;
  grid-template-columns: repeat(5, calc(55vw/5));

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 100%);
  }

  column-gap: 5px;
  row-gap: 2.5px;
  text-align: center !important;

  @media screen and (max-width: 768px) {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(-100%);

    &.menu-active {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .menu-item a {
    display: block;
    line-height: 30px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}

.sub-nav {
  display: none;
  position: absolute;
  padding: 5px 5px;
  list-style: none;
  width: 230px;

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    display: none;
    background-color: rgba(0, 0, 0, 0.20);
    box-sizing: border-box;
  }
}

.nav__link {
  color: #fff;
  text-decoration: none;

  &:hover+.sub-nav {
    display: block;
  }
}

.sub-nav {
  &:hover {
    display: block;
  }
}

/* Your other styles... */
</style>
