<template >
    <footer>
        <a
            href="https://api.whatsapp.com/send?phone=5516996044181&amp;text=Olá%20Ney%20Guincho%20Araraquara,%20Gostaria%20de%20solicitar%20um%20orçamento."><button
                class="btn-whats"><img  width="32" height="32" alt="Whatsapp" src="@/assets/whatsapp-32.svg"></button></a>
        <p>Copyright @ {{ year }} - Todos os direitos reservados</p>
    </footer>
</template>
<script >
export default {
  name: 'footer-cps',
  data: function () {
    return {
      year: ''
    }
  },
  created () {
    this.year = new Date().getFullYear()
  }
}
</script>
<style  scoped lang="scss">
$c_20d366: #28a745;
$n: none;
footer {
    padding: 1rem !important;
    background: #111111;
    * {
        color: #b9b9b9 !important;
        text-align: left !important;
        overflow: hidden !important;
    }
    .grid {
  div{
    border-radius: none;
    background: none;
  }
}
    border-top: solid .5rem #313133;
    padding-top: 2rem !important;
    strong {
        color: #e2e2e2 !important
    }
}
.btn-whats {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 70px;
    height: 70px;
    bottom: 40px;
    left: 90% !important;
    border: 1px solid #ccc;
    @media only screen and (max-width: 600px) {
        left: 65vw !important
    }
    z-index: 6;
    background: $c_20d366;
    border-radius: 1000px;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: $n;
    color:$n;
}
/* .btn::before {
    content: '';
    border-radius: 100px;
    min-width: calc(70px + 6px);
    min-height: calc(70px + 6px);
    border: 3px solid $c_20d366;
    position: $abs;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  } */
.btn:hover,
.btn:focus {
    color: #313133;
    transform: translateY(-6px);
}
.btn:hover::after,
.btn:focus::after {
    animation: none;
    display: none;
}
@keyframes ring {
    0% {
        width: 30px;
        height: 30px;
        opacity: 1
    }
    100% {
        width: 150px;
        height: 150px;
        opacity: 0
    }
}
</style>
