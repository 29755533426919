<template async>
  <nav>
    <Header/>
  </nav>
  <transition name="bounce">
  <router-view class="router"/>
  </transition>
  <Footer/>
</template>
<script async>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'app-main',
  components: {
    Header,
    Footer
  }
}
</script>
<style async lang="css">

/* @import  url(../src/css/main.min.css) screen and (min-width: 1080px);
@import url(../src/css/main.min.css) screen and (min-width: 640px);
@import url(../src/css/main.min.css) screen and (min-width: 256px); */
@import './scss/main.scss';
@import './css/screnn.css';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap');
</style>
