<template>
  <div>
    <div class="img -banner" v-for="(slide, index) in slides" :key="index"
      :style="{ 'display': slide.display, 'background-image': 'url(' + slide.Img + ')' }">
      <div class="n2-style">
        <div>
          <h1 class="font-hover">{{ slide.sublineSecondLine }}</h1>
          <p>
            <strong>A melhor opção em guincho em Araraquara e Região é na Ney Guincho Araraquara. </strong><br>
            Atuamos com dedicação e ética fornecendo o melhor serviço em auto socorro automotivo, reboque de veículos de
            todos os tipos e equipamentos em geral.
            Atuamos 24 horas por dia, atendemos em qualquer horário e localização que você se encontre na Região.
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=5516996044181&amp;text=Olá%20Ney%20Guincho%20Araraquara,%20Gostaria%20de%20solicitar%20um%20orçamento.">
            <button class="b1">
              <img class="icon32" src="@/assets/whatsapp-32.svg"> WhatsApp 24 Horas
            </button>
          </a><br><br>
          <a href="tel:16996044181">
            <button class="b2">
              <img class="icon32" src='@/assets/phone-32.svg' /> Ligue Agora 24 Horas
            </button>
          </a><br><br>
        </div>
      </div>
    </div>
  </div>
  <div class="g3">
    <ul>
      <li>
        <span class="title"> TE ATENDEMOS </span>
        <span class="text"> <strong>EM 20 </strong> MINUTOS </span>
      </li>
      <li class="skew">
        <h2 class="section_header">
          <a href="tel:16996044181">
            <span class="highlight thin ">
              <img width="32" height="32" alt="Whatsapp" src="@/assets/phone-32.svg">
              &nbsp;<strong>(16)&nbsp;99604-4181</strong>
            </span>
          </a>
        </h2>
      </li>
      <li>
        <div class="fw-column-inner padding_20">
          <div class="fw-divider-space"></div>
          <div class="media small-teaser shortcode-icon nitro-offscreen">
            <div class="media-left">
              <div class="icon-wrap">
                <i class="toyicon-phone black fontsize_18"></i>
              </div>
            </div>
            <div class="media-body">
              <span class="title">
                <strong>PROFISSIONAIS DISPONÍVEIS</strong>
              </span>
              <span class="text"> 24 HORAS POR DIA </span>
            </div>
          </div>
          <span class="theme-icon bg_icon icon_left">
            <i class="toyicon-phone default_icon"></i>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'banner-cps',
  data () {
    return {
      mensagemRecebida: '',
      currentSlide: 0,
      slides: [
        {
          sublineSecondLine: 'NEY GUINCHO ARARAQUARA',
          Img: require('@/assets/ney-guincho-araraquara.jpg'),
          display: 'none'
        },
        {
          sublineSecondLine: 'GUINCHO MAIS PRÓXIMO',
          Img: require('@/assets/ney-guincho-araraquara.jpg'),
          display: 'block'
        },
        // Adicione mais slides conforme necessário
        {
          sublineSecondLine: 'GUINCHO 24 HORAS',
          Img: require('@/assets/ney-guincho-araraquara.jpg'),
          display: 'none'
        }
        // E assim por diante
      ]
    }
  },
  mounted () {
    this.nextSlide()
  },
  methods: {
    nextSlide () {
      // Oculta todos os slides
      for (const slide of this.slides) {
        slide.display = 'none'
      }

      // Exibe o próximo slide
      this.slides[this.currentSlide].display = 'block'

      // Atualiza o índice para o próximo slide
      this.currentSlide = (this.currentSlide + 1) % this.slides.length

      // Chama recursivamente o próximo slide após 7 segundos
      setTimeout(this.nextSlide, 7000)
    }
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,700&display=swap');
// variables
$a: absolute;
// $f:fixed;
$fx: flex;
$n: none;
$bk: block;
$r: relative;
$l: left;
$cl: column;
$c1: #333333;
$c2: #fff;
$c3: #52c158;
$c10: #28a745;
$c4: #0c0c0c;
$c5: #717171;
$c6: #ff0c0c0c;
$c7: #f8f8f8;
$c8: #3d3d3c;
$c9: #001f2f;
$p: pointer;
$p_100: 100%;
$m1: 0.625rem;
$m2: 4.375rem;
$m3: 3.125rem;
$m4: 2.188rem;
$m5: 1rem;
/* $c3:#86BB71;
$c1:#94C2ED;
$c6:#E38968;
$c5:#92959E; */
$darkPurple: #f40b0b;
$lightBlue: #ccc;
$activeBlue: #f40b0b;
$white: #ffffff;
$lightPurple: #333333;
* {
  position: relative;
}
ul, section, div{
  max-width: 100vw!important;
  min-width: 100vw!important;
}

button.b1 {
  background: #28a745;
}

button.b2 {
  background: #0099cc;
}

button {
  border-radius: 50px;
  padding: .75rem 2.75rem;
  color: $white;
  box-shadow: 0 .5rem 1.5rem rgba(0, 0, 0, .25);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, .25);
  }

  @media only screen and (max-width: 600px) {
    min-width: 96vw;
    left: 2vw;
  }
}

* {
  color: #fff !important;
}

.g3 {
  *{
    opacity: .98;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, calc(100vw/3));

    @media only screen and (max-width: 600px) {
      display: none;
      grid-template-columns: repeat(1, 100vw);
    }

    background: #fff000;

    * {
      color: #111111 !important;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 600px) {
        min-height: 100px !important;
      }
    }

    .skew {
      background: #111111;
      *{
        color:#fff!important
      }

      h2 span a strong {
        color: #C92F63 !important;
      }

      padding: 10px;
      -webkit-transform: skew(-30deg);
      -ms-transform: skew(-30deg);
      transform: skew(-30deg);

      /* SKEW inverso para o texto não inclinar */
      @media only screen and (max-width: 600px) {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        /* SKEW inverso para o texto não inclinar */
      }
    }
  }
}

.elementor-headline--style-highlight svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  transform: translate(-50%, -50%);
  overflow: visible;
}

.elementor-2 .elementor-element.elementor-element-e772036>.elementor-shape-bottom svg,
.elementor-2 .elementor-element.elementor-element-e772036>.e-con-inner>.elementor-shape-bottom svg {
  height: 101px;
  transform: translateX(-50%) rotateY(180deg);
}

.elementor-shape .elementor-shape-fill {
  fill: #f7f7f7;
  transform-origin: center;
  transform: rotateY(0deg);
}

.elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.elementor-shape {
  transform: rotate(180deg);
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 450px;
  width: 100%;
  line-height: 0;
  direction: ltr;
}

* {
  overflow: hidden;
}

.example {
  background: none;
  display: -moz-box;
  /* Mozilla */
  display: -webkit-box;
  /* WebKit */
  height: 600px;
  /* Children should be oriented vertically */
  -moz-box-orient: vertical;
  /* Mozilla */
  -webkit-box-orient: vertical;
  /* WebKit */
  /* Align children to the horizontal center of this box */
  -moz-box-align: center;
  /* Mozilla */
  -webkit-box-align: center;
  /* WebKit */
  /* Pack children to the bottom of this box */
  -moz-box-pack: end;
  /* Mozilla */
  -webkit-box-pack: end;
  /* WebKit */
}

.img {
  height: 400px !important;
  background-repeat: no-repeat !important;
  background-size: cover;
  background-position: 80%;

  @media only screen and (max-width: 600px) {
    background-position: 50% 25%;
    height: 100% !important;
  }
}

.div-banner {
  position: absolute;
  bottom: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideteste {
  transition: 'transform 0.4s ease';
  transform: 'translateX(0%)';
  z-index: '1'
}

.elementor-headline.e-animated svg path {
  animation: elementor-headline-dash forwards;
  animation-duration: var(--animation-duration, 1.2s);
  animation-iteration-count: 1;
}

@keyframes elementor-headline-dash {
  0% {
    stroke-dasharray: 0 1500;
    opacity: 1
  }

  to {
    stroke-dasharray: 1500 1500;
    opacity: 1
  }
}

.n2-style {
  padding-top:100px;
  background: #111111;
  opacity: .91;
  h1, p {
    left: 2.5vw;
    max-width: 90% !important;
  }
}

.font-hover {
  font-family: 'Montserrat', sans-serif;
  color: #ffffff !important;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
  z-index: 99999 !important;
}
</style>
