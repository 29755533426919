import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/galeria-de-fotos',
    name: 'galeria-de-fotos',
    component: () => import('../components/Galeria.vue')
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../components/Servicos.vue')
  },
  {
    path: '/a-empresa',
    name: 'a-empresa',
    component: () => import('../components/Sobre.vue')
  },
  {
    path: '/fale-conosco',
    name: 'fale-conosco',
    component: () => import('../components/Contato.vue')
  },
  {
    path: '/:id',
    name: 'search',
    component: () => import('../components/Whatsapp.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return { el: to.hash }
    }
    return { top: 0 }
  }
})

export default router
