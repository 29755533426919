<template >
  <Banner/><br><br><br>
  <Cidades/><br><br><br>
</template>
<script>
import Banner from '@/components/Banner.vue'
import Cidades from '@/components/Cidades.vue'
export default {
  name: 'home-cps',
  components: {
    Banner,
    Cidades
  }
}
</script>
