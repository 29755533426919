<template>
  <h2>&nbsp;&nbsp;&nbsp;Cidades Atendidas</h2><br><br>
  <ul>
    <li v-for="item in this.list.slice().sort((a, b) => a.cidade.localeCompare(b.cidade))" :key="item.cidade">{{
      item.cidade }}</li>
  </ul>
</template>
<script>
import Cidades from '../json/cidades.json'
export default {
  name: 'cidades-cps',
  computed: {
    list () {
      return Cidades
    }
  }
}
</script>
<style lang="scss" scoped>
* {
  text-align: left;
  position: relative;
}

ul {
  left: 10vw;
  display: grid;
  grid-template-columns: repeat(4, calc(90vw/4));

  li {}

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 90vw);

    * {
      text-align: left;
    }
  }
}
</style>
